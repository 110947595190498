.disabledNetworkPanelCallout h5 {
  font-weight: 500 !important;
}

.disabledNetworkPanelOverlayMessage {
  position: absolute;
  align-content: center;
  top: 40%;
  width: 93%;
  opacity: 1;
  z-index: 101;
  pointer-events: none;
}

.disabledNetworkPanelOverlayBackground {
  display: block;
  height: inherit;
  position: relative;
  filter: blur(8px);
  cursor: default;
  background: none !important;
  opacity: 0.3;
  z-index: 100;
  min-height: 80px;
  pointer-events: none;
}
