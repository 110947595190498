.disabledText {
  opacity: 0.3;
  padding-left:10px;
}

.fileFormFieldUpload.mediumThumbnail .ant-upload-list-picture-card-container {
  width: 50% !important;
  height: auto;
}

.fileFormFieldUpload.largeThumbnail .ant-upload-list-picture-card-container {
  width: 100% !important;
  height: auto;
}

.fileFormFieldUpload.withoutBorder .ant-upload-list-item {
  border-color: white;
}

.ant-image{
    border:none!important;
}