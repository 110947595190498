.dynamic-form-modal {
  .ant-modal-body {
    z-index: 5000;
  }

  .dynamic-form {
    overflow-y: auto;
    label {
      text-transform: initial;
      font-weight: 600;
    }
  }
}

.odinFormOrderedList {
  padding-inline-start: 20px !important;
}

.select-option-description {
  color: gray;
  display: block;
  line-height: 16px;
  margin-top: -1px;
  font-size: 0.8rem;
}
.ant-select-selector .ant-select-selection-item {
  .select-option-value b {
    font-weight: 400;
  }
}
