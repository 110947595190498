.noteSection {
  .bp5-section-header {
    padding: 0 10px;
    min-height: auto;
  }
  &.bp5-section {
    overflow: auto !important;
  }
}

.noteBody .noteBodyActions {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.noteBody .noteBodyActions.opened {
  opacity: 1;
}

.noteBody:hover .noteBodyActions {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .noteBody .noteBodyActions {
    opacity: 1;
  }
}
