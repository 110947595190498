.routerDeviceCard .ant-card-head {
  padding: 0 5px;
}

.routerDeviceCard {
  margin-bottom: 20px;
}

.ont-view-wrapper {
  margin-top: 10px;

  .ont-view-controls {
    button {
      padding: 8px;
      line-height: 10px;
    }

    overflow-x: auto;
  }
}
