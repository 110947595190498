.filePreviewCard .ant-card-body {
  display: none;
}

.association-data-table-wrapper {

  margin-bottom: 24px;

  .association-table-card {
    background: #f3f2f2;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: #fafaf9;
      text-transform: capitalize;
      color: #514f4d;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ant-page-header-heading-title {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 16px;
  }

}

.association-card-wrapper {
  background: #ffffff;
  padding: 12px;
  border-top: 1px solid #f0f0f0;
}
