// .site-navigation-steps {
//   .ant-steps-item-title { max-width: 90px !important; }
//   .ant-steps-item-tail { display: none; }
// }

.stepModalSteps{
  padding:18px 10px 0 15px;
  
}

.stepModalStepsContainer{
  border-radius: 8px;
  background-color: white;
  margin:20px 15px 20px 15px;
  padding:10px 0 10px 10px;
  border: 1px solid #d9d9d9;
}

.stepModalStepsActions{
  padding: 0 20px 10px 20px;
}

.stepModalStepsButton{
  border-radius: 5px;
}

.stepModalStepsContent{
  border-radius: 8px;
  padding:12px;
  margin:15px;
  background-color: #e7f0ff;
  border:1px solid #c0cadc;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e4e4e4 !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e4e4e4 !important;
}

.steps-action {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: .5rem;
}
