.ant-modal{
  top:0;
}

.calendar-day-card {
  
  padding-top:5px;
  margin-top:10px;
  margin-bottom:10px;
  border-radius: 7px;
  
  .ant-card-head{
    min-height:auto;
    padding-left:10px;
    border-bottom:none;
  }

  .ant-card-head-title{
    font-size:0.9em;
    padding-top:2px;
    padding-bottom:2px;
  }

  .time-am {
    background-color: #d8edff;
    width: 145px;
    min-width: 145px;
    margin-right: 8px;
    border-radius: 5px;

    button {
      width: 145px;
    }
  }

  .time-am.ant-popover-disabled-compatible-wrapper {
    background-color: #dedede;
    color: #757575;


    button:disabled {
      background-color: #f1f1f1;
      border:none;
      color: #757575;
      width: 145px;
      border-radius: 5px;
    }
  }

  .time-pm {
    background-color: #032e61;
    color: #fff;
    width: 145px;
    min-width: 145px;
    margin-right: 8px;
    border-radius: 5px;

    button {
      width: 145px;
    }
  }

  .time-pm.ant-popover-disabled-compatible-wrapper {
    background-color: #dedede;
    color: #757575;

    button:disabled {
      background-color: #f1f1f1;
      border:none;
      color: #757575;
      width: 145px;
    }
  }

}
