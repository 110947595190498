.mobileCardIcon{
  font-size:1.1em;
  border-radius:100%;
  margin-right:13px;
}

.contactUpdateCard{
  border-radius:5px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .1);
}

.descriptionText{
  font-size:0.9em;
}