.recordProperties_small .ant-descriptions-item-content,
.recordProperties_small .ant-descriptions-item-label {
  padding: 5px 16px !important;
  font-size: 12px;
}

.recordProperties_smaller .ant-descriptions-item-content,
.recordProperties_smaller .ant-descriptions-item-label {
  padding: 3px 16px !important;
  font-size: 12px;
}

.recordProperties_small,
.recordProperties_smaller {
  width: 100%;
}

.clickableDescription:hover {
  text-decoration: underline;
  text-decoration-style: dotted;
}
