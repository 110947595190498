.moduleSelectorIcon{
  padding:6px;
  font-size:1.3em;
  margin-bottom:10px;
  background-color:#e4f2ff;
  border-radius:100%;
}

.ant-card-head-title{
  padding:12px 0;
}

.moduleSelectorCard{
  margin:13px 10px 0 10px;
  border-radius:5px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .1);
}