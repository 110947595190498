.ont-view-wrapper {
  .ant-card-body {
    padding: 4px;
  }

  .ont-view-controls {
    padding: 10px;
  }

  .webhook-results-wrapper {
    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      margin-top: 0;
      text-align: left;
      padding: 10px 15px 10px 10px;
    }

    .ant-tabs-left
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding-top: 10px;
      padding-left: 10px;
    }

    .webhook-result-icon-success {
      color: green;
    }

    .webhook-result-icon-error {
      color: red;
    }

    .webhook-result-icon-warning {
      color: #fca903;
    }

    .ant-tabs-content-holder {
      overflow-y: auto;
      position: relative;
    }

    .webhook-result-check-list {
      list-style: none;
      padding-left: 0;
      margin: 10px 0;

      .webhook-result-check-errors {
        margin-bottom: 0;
        margin-left: 18px;

        .webhook-result-check-error {
          margin-bottom: 5px;
        }

        .webhook-result-check-solution {
          line-height: 15px;
          margin-left: 10px;
          padding-top: 10px;

          .webhook-result-check-solution-line {
            margin-bottom: 10px;
          }
        }
      }
    }

    .webhook-results-loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .webhook-results-loader {
        text-align: center;

        p {
          margin: 10px 0 0 0;
        }
      }
    }
  }
}
