$bigThumbSize: 60px;
$smallThumbSize: 32px;

.NoteAttachments {
  .ant-upload-list-item-container {
    height: $bigThumbSize !important;
    width: $bigThumbSize !important;
  }

  .ant-upload-list-item {
    height: $bigThumbSize !important;
    width: $bigThumbSize !important;
    border-radius: 4px !important;
    padding: 0 !important;
  }

  .ant-upload-list-item:before {
    height: 100% !important;
    width: 100% !important;
  }

  .ant-upload-list-item-thumbnail {
    font-size: 9px !important;
  }

  .ant-upload-select {
    height: $bigThumbSize !important;
    width: $bigThumbSize !important;
    border-radius: 4px !important;
    font-size: 8px !important;
    background: white !important;
    padding: 0 !important;
    cursor: pointer !important;
    border: 1px dashed #d9d9d9 !important;
  }

  &.viewMode .ant-upload-select {
    display: none !important;
  }

  &.viewMode .ant-upload-list-item {
    height: $smallThumbSize !important;
    width: $smallThumbSize !important;
  }
  &.viewMode .ant-upload-list-item-container {
    height: $smallThumbSize !important;
    width: $smallThumbSize !important;
  }
  .ant-image-error {
    display: none;
  }
}
