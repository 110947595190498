.rawDataContainer{
  background: #dae0ea;
  color:black;
  border-radius: 7px;
  padding:5px;
  line-height: auto;
  tab-size: 4;
  overflow-y:scroll;
  overflow-x: scroll;
}

.rawDataContainer pre{
  margin-bottom:0;
  margin-right:5px;
}

pre
.string { 
  color: rgb(0, 114, 30); 
}
.number { 
  color: rgb(0, 114, 30); 
}
.boolean { 
  color:  rgb(0, 114, 30); 
}
.null { 
  color: black 
}
.key { 
  color: rgb(56, 53, 255); 
}

