$primary-color: #ec407a;
$nav-border-color: hsl(0, 0%, 100%);
$nav-border-width: 4px;
$nav-bg-color: #4bbc52;
$bg-color: #ffffff;
$active-color: #2d72d2;

.compactPipelineBadge {
  vertical-align: middle;
  margin-right: 8px;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.PipelineContainer {
  border: 1px solid #dddbda;
}

.PipelineContainer.Flat {
  margin-top: 10px;
  box-shadow: none;
  border: none;
}

.stageTitle {
  max-height: 20px;
  overflow: auto;
  font-size: 14px !important;
}

.nav-progress {
  display: table;
  overflow: hidden;
  font-weight: 500;
  color: black;
  padding: 0px;
  width: 100%;
  background-color: $bg-color;
  @include border-radius(2px);
  > div {
    width: 50px;
    background: #e9e9e9;
    position: relative;
    display: table-cell;
    padding: 7px 0;
    text-align: center;
    font-size: 1em;
    line-height: 1.25em;
    transition: width 100ms ease-out;
    &.complete {
      background-color: $nav-bg-color;
      color: #fff;
      .arrow {
        border: $nav-border-width solid $nav-border-color !important;
        background: $nav-bg-color !important;
      }
    }
    &.failed {
      background-color: #fa5a39;
      color: #fff;
      .arrow {
        background: #fa5a39 !important;
      }
    }
    &.active {
      background-color: $active-color;
      color: #fff;
      .arrow {
        background: $active-color !important;
      }
    }
    &.disabled {
      cursor: not-allowed !important;
    }
    &.hoverable:hover {
      width: 500px !important;
      cursor: default;
    }
  }
}

.arrow-wrapper {
  position: absolute;
  top: -12px;
  right: 0px;
  .arrow-cover {
    position: absolute;
    overflow: hidden;
    width: 32px;
    height: 50px;
    .arrow {
      position: absolute;
      left: -35px;
      z-index: 2;
      width: 54px;
      height: 58px;
      border: $nav-border-width solid $nav-border-color;
      background: transparent;
      @include border-radius(3px);
      @include rotate(45deg);
    }
  }
}
