@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';
// @import 'styles/colors';
// @import 'containers/IdentityManager/containers/UserLogin/styles';
// @use 'core/records/components/Forms/styles';

#fc_frame {
  left: 15px;
}

.ant-form-item-label > label {
  height: auto;
}

.bp5-dialog.bp5-multistep-dialog-nav-top {
  min-width: auto !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0 16px !important;
}

.dynamic-form-modal .dynamic-form label {
  height: auto;
}

#root {
  height: 100%;
  bottom: 0;
  background: #f1f3f7;
}

body {
  height: 100%;
  bottom: 0;
  background: #f1f3f7;
}

.app-container {
  height: 100%;
  bottom: 0;
  background: #f1f3f7;
}

// override ant
@media (max-width: 640px) {
  .ant-col-xs-12 {
    max-width: 100%;
    flex: auto;
  }
}

.ant-table-container {
  overflow: auto;
}

.ant-table-header {
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fafaf9;
      text-transform: capitalize;
      color: #514f4d;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.ant-table-row {
  .ant-table-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.ant-card-body {
  padding: 10px;
}

.record-detail-view {
  margin-right: 12px;
  margin-left: 12px;
  background: none;
}

.ant-empty-image {
  height: 50px;
}

.ant-empty-normal {
  margin: 0px;
}

.ant-layout {
  height: 100%;

  .record-detail-view {
    background: none;
  }
}

.ant-layout-header {
  height: 38px;
  line-height: 38px;
  padding: 0px;
}

.ant-card-head-title {
  font-weight: 700;
  text-transform: capitalize;
}

.page-layout {
  .header {
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1000;
  }

  .page-content {
    overflow: auto;
  }

  .sider-menu {
    overflow: auto;
    border-right: 1px solid #dadada;

    .ant-layout-sider-trigger {
      border-right: 1px solid #dadada;
    }
  }
}

.list-view {
  .page-header {
    padding: 1rem;
    border-bottom: 1px solid #dddbda;
    -moz-border-radius-topright: 0.25rem;
    -moz-border-radius-topleft: 0.25rem;
    background: #f3f2f2;
    box-shadow: none;

    .search-input {
      width: 400px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .list-view-table {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.data-table-page-header {
  padding: 1rem;
  border-bottom: 1px solid #dddbda;
  -moz-border-radius-topright: 0.25rem;
  -moz-border-radius-topleft: 0.25rem;
  background: #f3f2f2;
  box-shadow: none;
}

.page-content {
  margin-top: 8px;
}

.custom-static-search-wrapper {
  div {
    display: flex;
  }

  width: 400px;

  .undefined-action {
    position: relative;
    right: 32px;
    top: 0px;
    width: 34px;
    border-left: 1px solid #d9d9d9;
    padding-left: 8px;
    padding-top: 5px;
  }
}

.detail-body-wrapper {
  background: white;
  margin-top: 0.25rem;
  padding: 10px;
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #dddbda;
}

.ant-descriptions-item-label {
  font-weight: 700;
}

.record-detail-view {
  margin-top: 10px;
  background: #f1f3f7;

  .page-header {
    margin-bottom: 12px;
    background: #fff;
    background-clip: padding-box;

    .content {
      display: flex;
    }
  }

  .record-pipeline {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .record-main-content-row {
    margin-top: 4px;
  }
}

.identity-manager-wrapper {
  .ant-tabs-nav-wrap {
    padding-left: 10px;
  }

  .static-table-wrapper {
    height: calc(100vh - 295px);
  }
}

.hidden-field {
  height: 0;

  input {
    height: 0;
    min-height: 0;
    padding: 0;
    border: 0;
  }

  label {
    display: none;
  }
}

.page-header {
  .ant-page-header-heading-left {
    width: 100%;
  }
  .ant-page-header-heading-title {
    width: 100%;
    display: flex;
    .create-order-button {
      display: flex;
      margin-left: 0.5rem;
    }
  }
}

// .ant-steps-item {
//   .ant-steps-item-icon {
//     margin-left: 27px !important;
//     width: 16px;
//     height: 16px;
//     font-size: 9px;
//     line-height: 14px
//   }
//   .ant-steps-small {
//     font-size: 12px;
//     line-height: 19px;
//   }
// }
// .ant-steps-item::after {
//   width: 8px !important;
//   height: 8px !important;
//   margin-left: -16px !important;
// }
// .ant-steps-item-content {
//   width: 70px !important
// }

.ant-modal.add-contact-modal {
  top: 0 !important;
}

.dynamic-form.mobile-form {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-descriptions-item-content {
    padding: 0;
  }
  .ant-input-number,
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    border: none;
  }
  .ant-input-number-disabled,
  .ant-input-disabled,
  .ant-picker-disabled,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff;
    // cursor: auto !important;
    color: rgb(0, 0, 0);
    .ant-input-number-input {
      // cursor: auto !important;
      color: rgb(0, 0, 0);
    }
  }
  .ant-picker-disabled {
    input {
      // cursor: auto !important;
      color: rgb(0, 0, 0);
    }
  }
  .disabled-field {
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: not-allowed !important;
    height: 38px;
    .ant-select-selector {
      background-color: rgba(0, 0, 0, 0) !important;
      cursor: not-allowed !important;
    }
  }
}
.ant-ribbon-corner {
  display: none;
}
.ant-ribbon.ant-ribbon-placement-end {
  border-bottom-right-radius: 2px !important;
}
.ant-ribbon {
  top: -8px;
}
.add-appointment-modal {
  top: 0;
  .ant-modal-body {
    padding: 0.5rem;
  }
  .ant-card-head {
    padding: 0;
  }
  .time-am {
    width: 50%;
    background-color: #d8edff;
    .ant-btn {
      width: 100%;
    }
  }
  .time-pm {
    width: 50%;
    margin-left: 0.5rem;
    background-color: #032e61;
    color: #fff;
    .ant-btn {
      width: 100%;
    }
  }
}
