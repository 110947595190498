.uploadButton {
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #5c5c5c;
  padding: 12px 20px;

  &.error {
    color: #cc4246;
    border-color: #cc4246;
  }
}

.uploadButton:hover {
  background-color: #e2eeff !important;
}

.ant-upload-picture-card-wrapper.readOnly .ant-upload {
  display: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 100%;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}
