.replyBodySection {
  background-color: transparent;

  .bp5-section-header {
    padding: 0 10px;
    min-height: auto;
  }

  &.isEditing {
    border: 1px solid #2d72d2;
    background-color: white;
  }

  &.isEditing .bp5-section-header {
    display: none;
  }
  &.bp5-section {
    overflow: auto !important;
  }
}

.replyBodySection .replyBodyToolbar {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.replyBodySection:hover .replyBodyToolbar {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .replyBodySection .replyBodyToolbar {
    opacity: 1;
  }
}
