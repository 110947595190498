.sigCanvas{
    background:#f7f7f7;
}

.signatureUploadButton{
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    border: 1px dashed #d9d9d9;
    padding: 6px 20px;
  }